import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@features/auth/services/auth.service';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retry(1),
      catchError((e) => {
        if (e.status == 400) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: e.error.message,
          });
        } else if (e.status == 401) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: e.error.message,
          });
          this.router.navigate(['/auth/login']);
        } else if (e.status == 404) {
          this.router.navigate(['/error/not-found']);
        } else if (e.status == 422) {
          let errorMessages: any = [];
          Object.keys(e.error.data).map((x: any) => {
            errorMessages.push({ summary: x, message: e.error.data[x] });
          });

          errorMessages.map((error: any) => {
            this.messageService.add({
              severity: 'error',
              summary: error.summary,
              detail: error.message,
            });
          });
          return throwError(JSON.stringify(e.error.data));
        }
        const error = e.error;
        return throwError(error);
      })
    );
  }
}
