import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@features/auth/services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUserTokens = this.authService.currentUserTokens;
    let headers;
    if (currentUserTokens) {
      headers = req.headers.set(
        'Authorization',
        `Bearer ${currentUserTokens.access_token}`
      );
    }

    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}
