import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../interfaces/user.interface';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUserSubject: BehaviorSubject<any>;
  private loadUser: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient,
    private messageService: MessageService
  ) {
    this.currentUserSubject = new BehaviorSubject(null);
    if (isPlatformBrowser(this.platformId))
      this.currentUserSubject = new BehaviorSubject(
        JSON.parse(localStorage.getItem('user') as string)
      );
  }

  public get currentUserValue() {
    if (isPlatformBrowser(this.platformId)) return this.currentUserSubject;
    else return new BehaviorSubject(null);
  }

  getUserData() {
    if (this.loadUser.value != true) this.fetchUserData().subscribe();
    return isPlatformBrowser(this.platformId)
      ? this.currentUserSubject.asObservable()
      : new BehaviorSubject(null).asObservable();
  }

  changeCurrentUserData(userData: User | null) {
    if (isPlatformBrowser(this.platformId)) {
      this.currentUserSubject?.next(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    }
  }

  updatePhotoProfile(photo: any){
    return this.http.post(`${apiUrl}user/update_profile_picture`, photo).pipe(map(() => {
      return this.fetchUserData().subscribe()
    }));
  }

  updateUserProfile(userData: User){
    let bodyReq:any = userData
    bodyReq.birth_date = String(bodyReq.date_of_birth_month).padStart(2, '0') +
    '/' +
    String(bodyReq.date_of_birth_day).padStart(2, '0') +
    '/' +
    bodyReq.date_of_birth_year
    return this.http.put(`${apiUrl}user/update_profile`, bodyReq).pipe(map(() => {
      return this.fetchUserData().subscribe()
    }));
  }

  private fetchUserData() {
    return this.http.get(apiUrl + 'user/me').pipe(
      map((res: any) => {
        let userData = res['data'];
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('user', JSON.stringify(userData));
        }
        this.changeCurrentUserData(userData);
        this.loadUser.next(true);
        return userData;
      })
    );
  }

  resendVerifyEmail() {
    return this.http.post(apiUrl + 'user/resend_email_verification', '').pipe(
      map((res: any) => {
        this.handleSuccess(res['message']);
      })
    );
  }

  verfiyEmail(token: string) {
    return this.http
      .post(apiUrl + 'guest/verify_email', { email_verification_token: token })
      .pipe(
        map((res: any) => {
          this.handleSuccess(res['message']);
        })
      );
  }

  private handleSuccess(message: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message,
    });
  }
}
