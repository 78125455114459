import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env';

const appName = environment.appName;
const routes: Routes = [
  {
    path: '',
    title: appName,
    loadChildren: () =>
      import('./layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'verify-user-email/:token',
    loadChildren: () =>
      import('@features/verify-user-email/verify-user-email.module').then(
        (m) => m.VerifyUserEmailModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('@features/error/error.module').then((m) => m.ErrorModule),
  },
  // { // note: need check this route
  //   path: '**',
  //   redirectTo: 'error/not-found',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 96],
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
