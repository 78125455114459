import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env';
import { UserService } from '@features/user/services/user.service';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const ROOT_API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserTokensSubject: BehaviorSubject<any> = new BehaviorSubject(
    null
  );

  private facebookUserInfoSubject: BehaviorSubject<any>;
  fcmToken = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    // private socialAuthService: SocialAuthService,
    private userService: UserService,
    private router: Router,
    private messageService: MessageService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.currentUserTokensSubject = new BehaviorSubject(
        JSON.parse(localStorage.getItem('tokens') as string)
      );
    }
    this.facebookUserInfoSubject = new BehaviorSubject(null);
  }

  userTokenEmitter() {
    return isPlatformBrowser(this.platformId)
      ? this.currentUserTokensSubject.asObservable()
      : new BehaviorSubject(null).asObservable();
  }

  public get currentUserTokens() {
    return isPlatformBrowser(this.platformId)
      ? this.currentUserTokensSubject.value
      : '';
  }

  public get facebookUserInfoValue() {
    return isPlatformBrowser(this.platformId)
      ? this.facebookUserInfoSubject.value
      : '';
  }

  register(registerData: any) {
    const body = {
      title_of_courtesy: registerData.title_of_courtesy,
      name: registerData.name,
      email: registerData.email,
      birth_date:
        String(registerData.date_of_birth_month).padStart(2, '0') +
        '/' +
        String(registerData.date_of_birth_day).padStart(2, '0') +
        '/' +
        registerData.date_of_birth_year,
      password: registerData.password,
      password_confirmation: registerData.password_confirmation,
      gender: registerData.gender,
      captcha_token: registerData.captcha_token,
      subscription_mail: false,
    };
    return this.http.post(ROOT_API_URL + 'guest/register', body).pipe(
      map((res) => {
        return res;
      })
    );
  }

  login(loginData: any) {
    loginData.registration_token = this.fcmToken; //present fcm token
    return this.http.post(ROOT_API_URL + 'guest/login', loginData).pipe(
      map((res: any) => {
        const resData = res['data'];
        if (res.data.is_old_user) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'warn',
            detail: res.message,
          });
          return res;
        } else {
          this.handleAuthentication(resData.tokens).subscribe();
        }
      })
    );
  }

  verifyGoogleLogin(googleToken: string) {
    return this.http
      .post(ROOT_API_URL + 'guest/login-google', {
        access_token: googleToken,
      })
      .pipe(
        map((res: any) => {
          this.handleAuthentication(res.data.tokens).subscribe();
          return res;
        })
      );
  }
  verifyFacebookLogin(facebookToken: string, facebookEmail: string) {
    return this.http
      .post(ROOT_API_URL + 'guest/login-facebook', {
        access_token: facebookToken,
        email: facebookEmail,
      })
      .pipe(
        map((res: any) => {
          this.handleAuthentication(res.data.tokens).subscribe();
          return res;
        })
      );
  }

  forgotPassword(data: any) {
    return this.http.post(ROOT_API_URL + 'guest/forgot_password', data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  resetPassword(data: any) {
    return this.http.post(ROOT_API_URL + 'guest/reset_password', data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  logout() {
    let body;
    if (this.fcmToken) {
      body = { registration_token: this.fcmToken };
    }
    return this.http
      .post(ROOT_API_URL + 'user/logout', body)
      .pipe(
        map((res) => {
          this.handleLogout();
        })
      )
      .pipe(
        catchError((error) => {
          this.handleLogout();
          return throwError(error);
        })
      );
  }

  private handleLogout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('tokens');
      localStorage.removeItem('user');
    }
    // this.socialAuthService.signOut();
    this.userService.changeCurrentUserData(null);
    this.currentUserTokensSubject.next(null);
    this.facebookUserInfoSubject.next(null);
    this.router.navigate(['']);
  }

  private handleAuthentication(tokens: string) {
    //store user tokens to local storage
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('tokens', JSON.stringify(tokens));
      this.currentUserTokensSubject.next(tokens);
    }
    //store user data to local storage
    return this.http.get(ROOT_API_URL + 'user/me').pipe(
      map((res: any) => {
        const userData = res['data'];
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('user', JSON.stringify(userData));
          this.userService.changeCurrentUserData(userData);
          // this.handleSuccess(`Selamat Datang ${userData.name}`);
        }
        return userData;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
}
