import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UserService } from '@features/user/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'happybeauty-marketplace-sg';
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId: object,
    private translate: TranslateService,
    private router: Router,
    private userService: UserService
  ) {
    // routing pre page handle
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (isPlatformBrowser(this.platformId)) {
          if (this.userService.currentUserValue.value == null) {
            switch (event.url) {
              case '/user/dashboard':
                this.router.navigate(['/user-skinfit-nologin']);
                break;
              default:
                break;
            }
          }
        }
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }
  ngOnInit(): void {
    this.translate.setDefaultLang(this.locale);
  }
}
